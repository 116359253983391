import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { EventListBlock } from '/src/components/events'

const CommunityPage = () => {
  return (
    <Layout hero={<HeroImage title="Community" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../../images/cattle-yard.jpeg" 
                alt="SUPPORTING AUSTRALIA FOR OVER 60 YEARS"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">SUPPORTING AUSTRALIA FOR OVER 60 YEARS</h1>
              </div>
              <p className="mb-3">With a strong and innovative history, Metalcorp has developed into one of Australia's leading rural steel distribution businesses. 
              We serve the rural community with durable steel products, 
              using quality Australian-made steel developed with an acute understanding of the needs of Australian farmers. 
              We're in this for the long haul.</p>
              <Link className="button primary" to="/community/australian-heritage">
                Australia Heritage
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="blue-row mt-5 d-flex justify-content-center content-row">
        <Col sm={10}>
          <EventListBlock />
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">NATIONAL CENTRE FOR FARMER HEALTH</h1>
              </div>
              <p className="mb-3">Metalcorp has teamed up with the National Centre for Farmer Health (NCFH) to make a difference to farmer's 
              lives and give back to the rural community. Our focus is on combatting the “Ripple Effect,” 
              preventing farmers from taking their lives and stopping the enormous destruction this has on our rural communities.</p>
              <Link className="button primary" to="/">
                Learn more
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
            <Col sm={6}>
              <StaticImage 
                src="../../images/workingwith.jpg" 
                alt="NATIONAL CENTRE FOR FARMER HEALTH"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default CommunityPage

export const Head = () => <title>Community | Metalcorp</title>
